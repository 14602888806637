import { useFetcher } from '@remix-run/react'
import React from 'react'
import { ErrorResult } from '~common/generated/storefront-graphql'
import { useScript } from '~common/hooks/useScript'

/**
 * Be aware that even if you are logging in from http://localhost:3000, you have to
 * add http://localhost also to the javascript allowed origins
 *
 */
export const GoogleOneTap: React.FC<{
  action?: string
  redirectTo?: string | null
  googleClientId: string
}> = ({ googleClientId, action, redirectTo }) => {
  const googleButtonRef = React.useRef<HTMLDivElement>(null)
  const [isGoogleOneTapInitialized, setIsGoogleOneTapInitialized] =
    React.useState<boolean>(false)
  const scriptStatus = useScript('https://accounts.google.com/gsi/client', {
    defer: true,
    removeOnUnmount: true,
  })
  const fetcher = useFetcher<ErrorResult>()

  const handleResponse = React.useCallback(
    (response: google.accounts.id.CredentialResponse) => {
      console.log('Encoded JWT ID token: ' + response?.credential)
      const form: any = {
        strategy: 'google-one-tap',
        credential: response.credential,
      }
      if (redirectTo) {
        form.redirectTo = redirectTo
      }
      fetcher.submit(form, {
        action,
        method: 'POST',
      })
    },
    [],
  )

  React.useEffect(() => {
    if (scriptStatus !== 'ready' || isGoogleOneTapInitialized) {
      return
    }
    console.log('Initializing Google One Tap')

    const initializeGoogleSignIn = () => {
      if (isGoogleOneTapInitialized) {
        return
      }
      setIsGoogleOneTapInitialized(true)
      window.google.accounts.id.initialize({
        client_id: googleClientId,
        callback: (data: google.accounts.id.CredentialResponse) => {
          handleResponse(data)
        },

        // login_uri: 'http://localhost:3000/sign-in',
        // state_cookie_domain: 'http://localhost:3000',
        // auto_select: false,
        // cancel_on_tap_outside: false,
      })
      window.google.accounts.id.prompt() // Display the One Tap prompt automatically
    }

    initializeGoogleSignIn()
    // if (window.google) {
    //   initializeGoogleSignIn()
    // } else {
    //   window.onload = initializeGoogleSignIn
    // }

    // Clean up the onload handler when the component is unmounted
    return () => {
      //  window.handleCredentialResponse = undefined
      window.removeEventListener('load', initializeGoogleSignIn)
    }
  }, [scriptStatus, isGoogleOneTapInitialized])

  return (
    <>
      <div>
        <div ref={googleButtonRef}></div>
      </div>
    </>
  )
}
